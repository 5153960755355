import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import circle from '../../images/circle_primary.png';

const StepToGuarantee = (props) => {
  const {
    number = 1,
    heading = "Default step heading here",
    subheading = "Default step subheading here"
  } = props;

  const isLarge = useMediaQuery('(min-width:1200px)');

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        marginTop: { xs: '28px', lg: '54px' },
        paddingLeft: { xs: '20px', md: '100px', lg: '250px' },
        paddingRight: { xs: '20px', md: '100px', lg: '250px' },

      }}
    >

      <Stack
        direction="row"
      >

        <Box
          sx={{
            position: 'relative'
          }}
        >
          <img src={circle} width={isLarge ? 116 : 60 } alt="circle" />
          <Typography
            sx={{
              position: 'absolute',
              top: { xs: 15, lg: 34 },
              width: '100%',
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 900,
              fontSize: { xs: '32px', lg: '48px' },
              lineHeight: { xs: '32px', lg: '48px' },
              textAlign: 'center',
              color: '#000000',
            }}
          >
            {number}
          </Typography>
        </Box>

        <Stack
          direction="column"
          sx={{
            marginLeft: { xs: '20px', lg: '40px' },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 900,
              fontSize: { xs: '22px', lg: '36px' },
              lineHeight: { xs: '28px', lg: '48px' },
              textAlign: 'left',
              color: '#000000',
              paddingBottom: { xs: '20px', lg: '20px' },
            }}
          >
            {heading}
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 900,
              fontSize: { xs: '16px', lg: '24px' },
              lineHeight: { xs: '22px', lg: '30px' },
              textAlign: 'left',
              color: '#38718F',
              paddingBottom: { xs: '20px', lg: '20px' },
            }}
          >
            {subheading}
          </Typography>
        </Stack>

      </Stack>

    </Box>
  );
};

export default StepToGuarantee;