import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const WellBeInTouch = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '100%', lg: '100%' },
        marginTop: { xs: '28px', lg: '54px' },
        paddingLeft: { xs: '20px', lg: '250px' },
        paddingRight: { xs: '20px', lg: '250px' },

      }}
    >

      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs: '28px', lg: '54px' },
          lineHeight: { xs: '28px', lg: '55px' },
          textAlign: 'center',
          color: '#38718F',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        We’ll be in touch very soon to start the collaboration.
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs: '18px', lg: '26px' },
          lineHeight: { xs: '22px', lg: '30px' },
          textAlign: 'center',
          color: '#000000',
          paddingBottom: { xs: '20px', lg: '20px' },
        }}
      >
        To guarantee a launch date in fall 2022, please keep these important dates in mind:
      </Typography>

    </Box>
  );
};

export default WellBeInTouch;