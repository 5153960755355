import React, { useState, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import callApi from '../../services/apiService';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ReserveYourSpotFormInputPane from './reserveYourSpotFormInputPane';
import ContactFormConfirmationPane from './contactFormConfirmationPane';
import { Typography } from '@mui/material';

const ReserveYourSpotForm = (props) => {
  const {
    backgroundColor = '#1B7392',
    showTopLine,          // controls whether or not the top line of text is shown
  } = props;    
  const [showSpinner, setShowSpinner] = useState(false);


  const handleSubmitButtonClick = useCallback((data) => {
    console.log('contactForm.js.handleSubmitButtonClick() data: ', data);
    setShowSpinner(true);
    callApi('createSalesInquiry', null, data, (err, apiResponse) => {
      console.log('contactForm.handleSubmitButtonClick() createEmailSignup; err: ', err);
      console.log('contactForm.handleSubmitButtonClick() createEmailSignup; apiResopnse: ', apiResponse);
      let status = null;
      if (err) {
        status = 'error';
      } else if (apiResponse && apiResponse.data && apiResponse.data.status) {
        status = apiResponse.data.status;
      } else if (apiResponse && apiResponse.statusText && (apiResponse.statusText !== 'OK')) {
        status = 'error';
      }
      setShowSpinner(false);
      console.log('contactForm.handleSubmitButtonClick() about to set confirmationItem with status: ', status);
      setItems(confirmationItemFactory(status));
    });
  }, []);


  /**
   * Note that classNames="item" actually resolves to .item-enter, .item-enter-active, .item-exit, .item-exit-active, etc.
   */
  const formItemFactory = () => {
    return (
      // <CSSTransition key={0} timeout={500} in={true} classNames="item" unmountOnExit>
        <Box
          sx={{
            height: '400px'
          }}
        >
          <ReserveYourSpotFormInputPane onSubmit={handleSubmitButtonClick} />
        </Box>
      // </CSSTransition>
    );
  };

  const confirmationItemFactory = (status) => {
    return (
      // <CSSTransition key={1} timeout={500} in={true} classNames="item" unmountOnExit>
        <Box
          sx={{
            height: '400px'
          }}
        >
          <ContactFormConfirmationPane status={status} />
        </Box>
      // </CSSTransition>
    );
  };

  const [items, setItems] = useState(formItemFactory());

  return (
    <Box         
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      <Stack
        direction="column"
        sx={{
          maxWidth: { xs:'100%', lg: '750px' },
          margin: '0px auto',
          paddingTop: { xs:'20px', lg: '60px' },
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cooper Std',
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: { xs:'30px', lg: '54px' },
            lineHeight: { xs:'40px', lg: '60px' },
            letterSpacing: '-1.125px',
            color: '#FFFFFF',
            textAlign: 'center',
            
            // paddingBottom: { xs:'21px', lg: '60px' },
          }}
        >
          Reserve your spot in the next Bendable cohort.
        </Typography>

        <Box
          sx={{
            position: 'relative',
            backgroundColor: backgroundColor,
            margin: 0,
            height: '100%',
            paddingTop: { xs: '30px', lg: '60px' }
          }}
        >
          {items}
        </Box>
      </Stack>
    </Box>
  )
};

export default ReserveYourSpotForm;