import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CtaButton from './ctaButton';
import { Stack } from '@mui/material';

const QuestionsOrConcerns = (props) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        flexGrow: 1,
        background: '#1B7392',
        paddingTop: { xs:'61px', lg: '83px' },
        paddingBottom: { xs:'43px', lg: '80px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
        alignItems: 'center'
      }}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs:'100%', lg: '70%' },
          height: '100%',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >

          <Typography
            sx={{
              fontFamily: 'Sharp Sans',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: { xs:'25px', lg: '45px' },
              lineHeight: { xs:'30px', lg: '65px' },
              color: '#FFFFFF',
              paddingBottom: '32px',
            }}
          >
            In the meantime, if you have questions or concerns, feel free to contact Sarah Zaner at sarah@bendablelabs.com
          </Typography>


      </Box>

    </Box>
  );
};

export default QuestionsOrConcerns;